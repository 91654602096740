*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}

:root{
    --fullbackground: linear-gradient(90deg, #000, #121619);
    --textboxbackground: linear-gradient(90deg, #131313, #000000);
    --phrase-n: linear-gradient(90deg, #6e5f47, #81664d);
    --quote-n: linear-gradient(90deg, #ddbd82, #866041);
}

.ptdpdd{
    background: var(--fullbackground);
}
.ptcpdd{
    margin: 4rem 25%;
}

.ptcpdd h1{
    font-size: 1.4rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
}

.ptcpdd h3{
    font-size: 1rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
}

.ptcpdd h2{
    font-size: 1.2rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
}

.pvd{
    margin: 3rem 0;
}