@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Inconsolata:wght@200..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Inconsolata:wght@200..900&family=Indie+Flower&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Madimi+One&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+AU+QLD:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}

:root{
    --gradients:linear-gradient(90deg, #f1c40f, #f39c12);
    --gradient:linear-gradient(90deg, #5d98f1, #87bfff);
    --readm: linear-gradient(90deg, #2948bb, #313c42);
    --readm-hover: linear-gradient(90deg, #2176a7, #5577f1);
    --steam-background: linear-gradient(90deg, #0c0c0c, #da7e32);
    --steam-title: linear-gradient(90deg, #292929, #f1b126);
    --phrase: linear-gradient(90deg, #000, #121619);
}

img{
    max-width: 100%;
}

.home{
    background: var(--phrase);
}

.sec-phrase{
    padding: 10rem 14rem;
    width:100%;
    background-image: url('../assets/images/Científicos.png');
    background-size: cover;
    background-attachment: fixed;
}


.phrase{
    font-size: 2rem;
    color:#fff;
    width:100%;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding: 1.4rem;
}

.phrase blockquote{
    text-align:center;
    padding: 3rem 0.3rem;
}

.phrase h3{
    font-size: 1.4rem;
    /* margin-left: 44rem; */
    text-align: right;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
}

section.pri{
    display:flex;
    flex-direction: column;
}

/* SECTION THE BIG SCIENCE */

.title{
    margin-top: 3rem;
}

.title a {
    text-align: center;
    margin-left: 36%;
    margin-right: 36%;
    position: relative;
    display: block;
    transition: color 0.5s ease;
    color: #fff; /* Ensure the link starts with the inherited color */
    padding: 10px; /*Add some padding for better visibility */
    z-index: 1; /* Ensure the text is above the pseudo-element */
    font-size: 2.5rem
}

.title a::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transform: scaleY(0);
    transform-origin:bottom;
    transition: transform 0.5s ease;
    z-index: -1; /* Ensure the background is behind the text */
}

.title a:hover::after {
    transform: scaleY(1);
}

.title a:hover {
    color: #333; /* Changes text color to #333 on hover */
}

/* SECTION THE BIG SCIENCE */

section.tbs{
    width: 100%;
    height:100%;
}

section.tbs .square-tbs{
    display: grid;
    width:100%;
    grid-template-rows: 500px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 30px;
    grid-column-gap: 50px;
    flex-direction: row;
    padding: 4rem;
}

.a-hb{
    background-image: url("../assets/images/3f2fe14b-caae-440a-b737-b340516e66bd.png");
    background-size: cover;
    background-position:50%;
    transition: all .5s ease-in;
    box-shadow:  12px 8px 12px rgba(64, 179, 199, 0.1);
}
a.a-hb:hover{
    transform: scale(0.9);
}


.hb-title{
    margin-top: 20rem;
    padding: 4.1rem 0;
    background: rgba(0, 0, 0, 0.5);
}

.hb-title h1{
    font-size: 1.8rem;
    color:#fff;
    text-align:center;
    font-family: "Mukta", sans-serif;
    font-weight: 700;
    font-style: normal;
}

/* .hb-text .hb-description{
    text-align: justify;
}

.hb-text .hb-description h3{
    font-size: 1.2rem;
    color:#fff;
    font-family: "Acme", sans-serif;
    font-weight: 400;
    font-style: normal;
} */



.a-bh{
    background-image: url("../assets/images/2819ec84-b8f9-4a27-82d9-1c0e167d95be.png");
    background-size: cover;
    background-position:100%;
    transition: all .5s ease-in;
    box-shadow:  12px 8px 12px rgba(64, 179, 199, 0.1);
}

a.a-bh:hover{
    transform: scale(0.9);
}


.a-bb{
    background-image: url("../assets/images/5abf2705-4005-4dcb-9259-bbb39c3ba187.png");
    background-size: cover;
    background-position: center;
    transition: all .5s ease-in;
    box-shadow:  12px 8px 12px rgba(64, 179, 199, 0.1);
}

a.a-bb:hover{
    transform: scale(0.9);
}


/* .hidden{
    opacity: 0;
    transition: all 1s;
    filter: blur(5px);
    transform: translateX(-100%);
}
.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
} */

/*STEAM EDUCATION*/

section.steam{
    width: 100%;
    height:100%;
    justify-content: space-between;
    background: var(--steam-background) ;
}

.square-steam{
    display: flex;
    flex-direction: row;
    margin: 6%;
}

.steam-text{
    display: flex;
    flex-direction: column;
}

.steam-title{
    color:#fff;
}

.steam-title a{
    padding: 1rem 0.5rem;
    width:70%;
    border-radius: 0.5rem;

    position: relative;
    display: block;
    transition: all 1s ease;
    color: #fff; 
    padding: 10px;
    z-index: 1; 
}

.steam-title a::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1;
    width: 100%;
    height: 100%;
    background: var(--steam-background);
    transform: scaleX(0);
    transform-origin:left;
    transition: transform 0.5s ease;
    z-index: -1; 
    border-radius: 0.3rem;
}

.steam-title a:hover::after{
    transform: scaleX(1);
}

.steam-title a h1{
    font-size: 2rem;
    width:70%;
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
}

.steam-title a:hover{
    padding-left: 5rem;
    color: #e2e467;
}

.steam-title hr{
    color:#f1b126;
    width:95%;
}

.steam-description{
    color: #fff;
    padding: 2rem 3rem;
}

.steam-description h3{
    font-size: 1.1rem;
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    text-align: justify;
}

.steam-image{
    padding:0 2rem;
}

.steam-image img{
    width:100%;
    max-width: 110%;
    min-width: 70%;
    clip-path: circle(50.3% at 50% 50%);}

/* THE BIG SCIENCE */

.section-bigscience{
    width:100%;
    padding: 2rem 3rem;
}

.section-bigscience hr{
    color: #a39d9d;
}

.section-bigscience h2{
    color:#fff;
    font-size: 3.3rem;
    padding: 1rem;
    font-family: "Inconsolata", monospace;
}

/* Topics section */

section.topics-more{
    background: var(--phrase);
}

.topics{
    width:100%;
    display: grid;
    grid-template-rows: 400px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    flex-direction: row;
    padding: 1.5rem 3rem 1.7rem 3rem;
}

.bi a{
    text-align: center;
}

a.cientifico{
    background: url("../assets/images/scientist\ a79aec6d-62a0-4132-9979-71b50dfeb132.png");
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all .2s ease-in;
}

a.tecnologo{
    background: url("../assets/images/programmer\ codi\ 3c8059b3-842b-4069-b8db-27cda5b54ee7.png");
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all .2s ease-in;
}

a.ingeniero{
    background: url("../assets/images/openart-image_83oAfSuq_1721067890535_raw.png");
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all .2s ease-in;
}

a.artista{
    background: url("../assets/images/openart-image_BlvZfEnS_1721067923187_raw.png");
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all .2s ease-in;
}

a.matematico{
    background: url("../assets/images/openart-image_Q6VibSCG_1721067782671_raw.png");
    background-size: cover;
    background-position: center;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all .2s ease-in;
}

.bi a.prof h3{
    font-size: 1.5rem;
    padding: 180px 0rem;
    color:#fff;
}

a.cientifico:hover{
    -webkit-filter: grayscale(0);
    filter:none;
    transform: scale(1.1);
}

a.tecnologo:hover{
    -webkit-filter: grayscale(0);
    filter:none;
    transform: scale(1.1);
}

a.ingeniero:hover{
    -webkit-filter: grayscale(0);
    filter:none;
    transform: scale(1.1);
}

a.artista:hover{
    -webkit-filter: grayscale(0);
    filter:none;
    transform: scale(1.1);
}

a.matematico:hover{
    -webkit-filter: grayscale(0);
    filter:none;
    transform: scale(1.1);
}

@media (max-width:600px) {
    .sec-phrase{
        padding: 1rem 3rem;
        width:100%;
        background-image: url('../assets/images/Científicos.png');
        background-size: cover;
        background-attachment:inherit;
    }
    .phrase{
        font-size: 1rem;
      padding: 1rem;
    }
    
    .phrase blockquote{
        text-align:center;
        padding: 2rem 0.3rem;
    }
    
    .phrase h3{
        font-size: 1rem;
    }
    .title a {
        text-align: center;
        padding: 14px;
        font-size: 2rem;
    }
    section.tbs .square-tbs{
        display: grid;
        width:100%;
        grid-template-rows: 400px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-row-gap: 30px;
        grid-column-gap: 50px;
        flex-direction: row;
        padding: 4rem;
    }
    .hb-title{
        margin-top: 14.1rem;
    
    }
    
    .hb-title h1{
        font-size: 1.6rem;
    
    }
    .square-steam{
        display: flex;
        flex-direction: column;
        margin: 7%;
    }
    .steam-title a{
        padding: 1rem 0rem;
        width:100%;
    }
    .steam-title a h1{
        font-size: 2rem;
        width:100%;
        font-family: "Work Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
    }
    
    .steam-title a:hover{
        padding-left: 3rem;
        color: #e2e467;
    }
    .steam-image img{
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);}
    
    .section-bigscience h2{
        font-size: 2.2rem;
    }
}