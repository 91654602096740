@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}
:root{
    --bg-list: linear-gradient(90deg, #161515, #2d3941);
}
.father{
    /* height:54rem; */
    display:flex;
    height:auto;
}
.sidebar{
    width:15rem;
    height: calc(100vh);
    /* height: 100vh; */
    border-right: 2px solid #c4ccde;
    top:0;
    position: sticky;
    /* overflow: visible; */
    overflow-y: auto;
}

.sidebar ul{
    list-style: none;
    padding: 3.085rem 1rem;
    /* background-color:  rgba(27, 35, 58, 0.5); */
    background: var(--bg-list);
    display: flex;
    flex-direction: row;
}

.sidebar ul:hover{
    background-color:#4486b3 ;
}
.icon{
    margin:0rem 0.3rem;
    font-size: 1.5rem;
    color:#fff;
}
.sidebar ul li{
    margin-bottom: 2rem;
    color: #fff;
    font-size: 1.1rem;
    font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0rem 0.8rem;
}
