@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Questrial&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Maven+Pro:wght@400..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Questrial&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}
:root{
    --phrase: linear-gradient(90deg, #000, #121619);
}
.tpcs{
    width:100%;
    display: flex;
    flex-direction: column;
    height:46rem;
    background: var(--phrase);
}
.mtpc{
    padding: 6rem 6rem 4rem 6rem;
    text-align: center;
}
.mtpc h1{
    font-size: 2.8rem;
    color:rgb(231, 228, 224);
    margin: 1rem 0rem;
    font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

/* TOPICS */
.tpn{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(2, 1fr));
    gap: 30px;
    padding: 1rem 10rem;
}
/* FIRST TOPIC */
.tpo{
    grid-column: 1;
    grid-row: 1;
    position: relative; }

.htp {
  position: relative;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
}
.htp:hover{
    text-align: center;
    transform: scale(1.1);
    transition: all 3s ease;
}
.tpo a{
    width: 100%;
    background-color: rgb(46, 63, 83);
    display: block;
    padding: 2rem 1rem;
}
.seetop{
    display: none;
    position: relative;
    background-color: #1d1c1c9f;
    border-radius: 1rem;
    margin: 2rem 15%;
    padding: 2.5%;
    transition: display 2s;
    text-align: justify;

}

.seetop h1{
    font-size: 1.2rem;
    color: rgb(180, 178, 178);
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.htp:hover .seetop{
    display: block;
    transition: all 3s ease;
}
.tpo a h2{
    color: rgb(224, 223, 223);
    font-size: 1.6rem;
    font-family: "Maven Pro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.amo:hover{
    background-image: url('../assets/images/Designer\(7\).jpeg');
    background-size: cover;
    background-position: center;
    transition: all 3s ease;
}
.amo:hover .h2tt{
    color:#000;
}

/* SECOND TOPIC */
.tpt{
    grid-column: 1;
    grid-row: 2;
}

.ams:hover{
    background-image: url('../assets/images/Designer\(6\).jpeg');
    background-size: cover;
    background-position: center;
    transition: all 3s ease;
}
.ams:hover .h2tt{
    color: #000;
}
.tpt a{
    width: 100%;
    background-color: rgb(46, 63, 83);
    display: block;
    padding: 2rem 1rem;
}
.tpt a h2{
    color: rgb(240, 237, 237);
    font-size: 1.6rem;
    font-family: "Maven Pro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}