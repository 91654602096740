@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Maven+Pro:wght@400..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Questrial&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Maven+Pro:wght@400..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Questrial&family=Quicksand:wght@300..700&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&display=swap');
*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}
:root{
    --full: linear-gradient(90deg, #000, #121619);
}
.flabm{
    background: var(--full);
}
.prabm{
    display: grid;
    grid-template-columns: repeat(auto, minmax(2,1fr));
    grid-template-rows: repeat(auto, minmax(1,1fr));
    margin: 6rem;
}
.prabmtxt{
    grid-column: 1;
    grid-row: 1;
    margin: 0 1rem;
    align-content: center;
}
.prabmtxt h2{
    font-size: 3rem;
    margin-bottom: 1.4rem;
    text-align: center;
    color:#e4e2e2;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
}
.prabmtxt h3{
    color:#cfcdcd;
    font-family: "Quicksand", sans-serif;
    font-weight: 200;
}
.prabmig{
    grid-column: 2;
    grid-row: 1;
}
.prabmig img{
    clip-path: circle(50% at 50% 50%);
}
.pabd{
    display: grid;
    grid-template-columns: repeat(auto,minmax(1,1fr));
    grid-template-rows: repeat(auto, minmax(1,1fr));
}
.pabdsp{
    grid-row: 1;
    grid-column: 1;
    margin: 1rem 14%;
    padding: 1.2rem;
    background-color:rgb(56, 59, 58);
}
.pabdsp h2{
    font-family: "Edu AU VIC WA NT Hand", cursive;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-size: 1rem;
    color:#cfcdcd;
}
.pabdsp h3{
    font-family: "Edu AU VIC WA NT Hand", cursive;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    color:#cfcdcd;
    text-align: right;
}
.nuvi{
    margin: 6rem;
    display: grid;
    grid-template-columns: repeat(auto, minmax(2,1fr));
    grid-template-rows: repeat(auto, minmax(1,1fr));
}
.desnuvi{
    grid-column: 2;
    grid-row: 1;
    margin: 2rem 8rem 2rem 6rem;
}
.desnuvi h2{
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 200;
    font-size: 1.4rem;
    text-align: justify;
}

.titnuvi{
    grid-column: 1;
    grid-row: 1;
    align-content: center;
}
.titnuvi h2{
    font-size: 3rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    text-align: center;
}

.imso{
    margin:6rem;
    display: grid;
    grid-template-columns: repeat(auto, minmax(2,1fr));
    grid-template-rows: repeat(auto, minmax(1,1fr));
}
.deim{
    grid-column: 1;
    grid-row: 1;
    margin: 0 1rem;
    align-content: center;
}
.deim h2{
    font-size: 2.5rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0.5rem;
}
.deim h3{
    padding: 0.4rem;
    font-size: 1.2rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    text-align: justify;
}
.imsoig{
    grid-column: 2;
    grid-row: 1;
    align-content: center;
}
.imsoig img{
    border-radius: 1.4rem;
}

@media (max-width:600px) {
    .prabm{
        display: grid;
        grid-template-columns: repeat(auto, minmax(1,1fr));
        grid-template-rows: repeat(auto, minmax(2,1fr));
        margin: 4rem;
    }
    .prabmtxt{
        grid-column: 1;
        grid-row: 1;
        margin: 1rem 1rem 2rem 1rem;
        align-content: center;
    }
    .prabmtxt h2{
        font-size: 2rem;
    }
    .prabmtxt h3{
        text-align: justify;
    }
    .prabmig{
        grid-column: 1;
        grid-row: 2;
    }
    .prabmig img{
        clip-path: circle(45% at 50% 50%);
    }
    .nuvi{
        margin: 4rem;
        display: grid;
        grid-template-columns: repeat(auto, minmax(1,1fr));
        grid-template-rows: repeat(auto, minmax(2,1fr));
    }
    .desnuvi{
        grid-column: 1;
        grid-row: 2;
        margin: 1rem;
    }
    .desnuvi h2{
        font-size: 1.2rem;
        text-align: justify;
    }
    
    .titnuvi{
        grid-column: 1;
        grid-row: 1;
        align-content: center;
    }
    .titnuvi h2{
        font-size: 2rem;
        text-align: center;
    }
    .imso{
        margin:4rem;
        display: grid;
        grid-template-columns: repeat(auto, minmax(1,1fr));
        grid-template-rows: repeat(auto, minmax(2,1fr));
    }
    .deim{
        grid-column: 1;
        grid-row: 1;
        margin: 0 1rem;
        align-content: center;
    }
    .deim h2{
        font-size: 2rem;
    }
    .imsoig{
        grid-column: 1;
        grid-row: 2;
        align-content: center;
        margin: 2rem 0 ;
    }
}