@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}

:root{
    --fullbackground: linear-gradient(90deg, #000, #121619);
    --backgroundyt: linear-gradient(90deg, #ebe8e8, #e94b4b);
    --backgroundythover: linear-gradient(90deg, #e94b4b, #ebe8e8);
    --backgroundx: linear-gradient(90deg,  #231d47, #222120);
    --backgroundxhover: linear-gradient(90deg, #222120, #231d47);
    --backgroundig: linear-gradient(90deg,   #e03894, #f39c12);
    --backgroundighover: linear-gradient(90deg, #f39c12, #e03894);
    --backgroundgmail: linear-gradient(90deg,   #e94b4b, #63d496, #f3e523);
    --backgroundgmailhover: linear-gradient(90deg, #f3e523, #63d496, #e94b4b);
    --backgroundcontacts: linear-gradient(90deg, #222222, #333333);
}

.contacts{
    background: var(--fullbackground);
}

section.contact-us{
    margin: 3rem;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-rows: 400px;
    grid-column-gap: 30px;
    flex-direction: row;
}

section.contact-us .img-sec{
    align-content: center;
    height: 100%;
}

section.contact-us .img-sec img{
    width:100%;
}

section.contact-us .contact-types{
    height: 100%;
    align-content:center;
}


/*  */
.sei {
    margin: 25px;
    padding: 1.2rem 1rem;
    display: flex;
    flex-direction: row;
    position: relative; /* Ensure the pseudo-element is positioned relative to this */
    z-index: 1;
    background: var(--backgroundcontacts);
  }

a.youtube{
    color: #e94b4b;
    font-size: 1.4rem;
}

a.Xtwitter{
    color: #333357;
    font-size: 1.4rem;
}

a.instagram{
    color: #f39c12;
    font-size: 1.4rem;
}

a.gmail{
    color: #f3e352;
    font-size: 1.4rem;
}
  
  .sei h2 {
    font-size: 1.2rem;
    font-family: "PT Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: none;
    position: relative;
    z-index: 1;
  }
  
  .sei.yout::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--backgroundythover);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease;
    z-index: -1; /* Ensure the background is behind the text */
  }
  
  .sei.yout:hover::after {
    transform: scaleX(1);
  }

  .sei.xs::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--backgroundxhover);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease;
    z-index: -1; /* Ensure the background is behind the text */
  }
  
  .sei.xs:hover::after {
    transform: scaleX(1);
  }

  .sei.igs::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--backgroundighover);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease;
    z-index: -1; /* Ensure the background is behind the text */
  }
  
  .sei.igs:hover::after {
    transform: scaleX(1);
  }

  .sei.gm::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--backgroundgmailhover);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease;
    z-index: -1; /* Ensure the background is behind the text */
  }
  
  .sei.gm:hover::after {
    transform: scaleX(1);
  }
  
  .sei.yout:hover h2.yty,
  .sei.xs:hover h2.xsx,
  .sei.igs:hover h2.is,
  .sei.gm:hover h2.gl {
    display: block;
  }
  
/* 
  .sei {
    margin: 30px;
    padding: 1.2rem 1rem;
    display: flex;
    flex-direction: row;
    position: relative; 
    z-index: 1;
    background: var(--backgroundcontact);
  }
  
  .sei h2 {
    font-size: 1rem;
    display: none;
    position: relative;
    z-index: 1;
  }
  
  .sei::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--backgroundcontacthover);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease;
    z-index: -1; 
  }
  
  .sei:hover::after {
    transform: scaleX(1);
  }
  
  .sei.yout:hover h2.yty,
  .sei.xs:hover h2.xsx,
  .sei.igs:hover h2.is,
  .sei.gm:hover h2.gl {
    display: block;
  }
   */

@media (max-width:600px) {
  section.contact-us{
    margin: 1rem 0rem;
    align-content: center;
    height: 100%;
    width:100%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
    grid-template-rows:  repeat(auto-fit, minmax(2, 1fr));;
}
  section.contact-us .img-sec{
    grid-row: 2;
    grid-column: 1;
    display: flex;
    align-content: center;
    height: 100%;
    width:100%;
    justify-content: center;
  }
  .sei {
    margin: 25px 35px;
    padding: 1.2rem 1rem;
  }
  section.contact-us .img-sec{
    grid-row: 1;
    grid-column: 1;
}
}