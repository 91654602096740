@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}

:root{
    --fullbackground: linear-gradient(90deg, #000, #121619);
    --textboxbackground: linear-gradient(90deg, #131313, #000000);
    --phrase-s: linear-gradient(90deg, #353433, #464a4e);
    --quote-s: linear-gradient(90deg,  #363329, #343435);
}

.grid{
    display:grid;
    grid-template-rows: repeat(auto-fit, minmax(450px, 1fr));;
    gap:10px;
    grid-template-columns: 100%;
    width:100%;
    height:100%;
    background: var(--fullbackground);
    z-index: 1;
}
.bg{
    width: 100%;
}
.bg-f video{
    width: 100%;
    height: 25rem;
    object-fit: cover;
}
.title-f{
    position:absolute;
    width: 100%;
    height: 25rem;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:5rem 0 ;
    background-color: rgba(0, 0, 0, 0.3);
} 
.title-f h1{
    /* font-family: "Righteous", sans-serif; */
    font-family: "Carter One", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;
    color:#6887b1;
}
.pse{
    margin: 5rem 12rem;
}
.prse-j{
    background: var(--phrase-s);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(2, 1fr));
    grid-template-rows: minmax(100px, auto);
    justify-content: space-between;
}
.blh-j{
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    background: var(--quote-s);
    margin-left:2rem;
    margin-right: 2rem;
}
.blh-j blockquote{
    padding: 4rem 4rem 1rem 4rem;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
    color:#cfcbcb;
}
.blh-j h3{
    padding: 1.4rem;
    text-align: right;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color:#d6d3d1;
}
.img-prse{
    grid-column: 2;
    grid-row: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.img-prse img{
    width: auto;
    height: 100%;
    object-fit: cover;
    /* height:22rem; */
    /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 74% 100%); */
}
.grid-box-tops{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(2, 1fr));
    grid-template-rows: minmax(100px, auto);
}
.top{
    margin: 4.5rem 6rem;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    transition: all 1s ease;
}
.top:hover{
    transform: scale(1.1);
}
.top:hover .names{
    transform: scale(1.1);
    opacity: 0;
}
.top:hover .descriptions{
    opacity: 1;
    transform: scale(1.1);

}
.descriptions{
    margin: -32rem 0;
    padding: 0rem 3rem;
    opacity: 1;
    z-index: 1;
    position: absolute;
}
.descriptions{
    opacity: 0;
    z-index: 2;
}
.descriptions h2{
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #e7e1e1;
  font-size: 0.9rem;
}
.names{
    margin-top: 14.9rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.85rem 0;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    transition: opacity 0.3 ease;
    opacity:1;
    z-index: 1;
}
.names h2{
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #e7e1e1;
}
.py{
    grid-column: 1;
    grid-row: 1;
    background-image: url('../assets/images/Python.jpeg');
    background-position: center;
    background-size: cover;
    box-shadow:  12px 8px 12px rgba(69, 158, 136, 0.1);
}
.py::before{
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(78, 133, 110, 0.7);
    background-size: cover;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
}
.py:hover::before{
    display: block;
}
.web-d{
    grid-column: 2;
    grid-row: 1;
    background-image: url('../assets/images/Web_development.jpeg');
    background-position: center;
    background-size: cover;
    box-shadow:  12px 8px 12px rgba(119, 116, 114, 0.1);
}
.web-d::before{
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(192, 162, 108, 0.7);
    background-size: cover;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
}
.web-d:hover::before{
    display: block;
}
/* .mecac{
    grid-column: 1;
    grid-row: 2;
    background-image: url('../assets/images/fa9f734a-d23a-41ff-90ff-3f2c7b6d1da4.png');
    background-position: center;
    background-size: cover;
    box-shadow:  12px 8px 12px rgba(85, 104, 190, 0.1);
}
.mecac::before{
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(65, 68, 112, 0.7);
    background-size: cover;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
}
.mecac:hover::before{
    display: block;
}
.nuuni{
    grid-column: 2;
    grid-row: 2;
    background-image: url('../assets/images/8889ca3d-5819-471a-b4f8-824b92688cf9.png');
    background-position: center;
    background-size: cover;
    box-shadow:  12px 8px 12px rgba(36, 35, 35, 0.7);
}
.nuuni::before{
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color:  rgba(45, 47, 53, 0.7);
    background-size: cover;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
}
.nuuni:hover::before{
    display: block;
} */
