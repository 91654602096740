@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.nav-bar{
    width: 100%;
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(1, 1fr));
}
.navbar {
    width: 100%;
    height: 100px;
    background-color: #121619;
    display: flex;
    flex-direction: row;
}

.navbar .leftSide{
    flex:50%;
    display:flex;
}

.navbar .leftSide label{
    font-size:36px;
    color:#fff;
    font-weight: 600;
    font-family: "Cabin", sans-serif;}

.navbar .leftSide{
    /* padding: 1.7rem 6rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.navbar .rightSide{
    flex:50%;
    display:flex;
    justify-content: center;
    align-items: center;
    /* height:100%; */  
}


.navbar .rightSide a{
    padding: 0.8rem 2.5rem;
    color:#fff;
    text-decoration: none;
    font-size: 1.09rem;
    font-family: "Lato", sans-serif;
    font-weight: 600;
}

.navbar .rightSide a:hover{
    background-color: #fff;
    border-radius: 1rem;
    color: #000;
}
.navbar-2{
    padding: 0.4rem;
    background: #121619;
}

.nav-center{
    flex:50%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.nav-center a{
    padding: 0 2.5rem;
    color:#fff;
    text-decoration: none;
    font-family: "Lato", sans-serif;
}

.nav-center a::after{
    content: ""; 
    height:2px;
    width:0%;
    background: #ffffff;
    display: block;
    box-shadow: 0px 0px 2px #000;
    border-radius: 10px;
    bottom: 5px;
    right:0%;
    transition: .3s ease-in-out;
}

.nav-center a:hover::after{
    content:'';
    width: 100%;
    left:0%;
    height:2px;
    display: block;
}

@media (max-width:600px) {
    .nav-bar{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(1, 1fr));
    }
    .navbar-1{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(2, 1fr));
    }
    .navbar{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(2, 1fr));
    }
    .navbar-2{
        padding: 1rem 0 1rem 0;
        display: none;
    }
    .rightSide{
        padding: 1rem 0;
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .leftSide{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2rem 0;
    }
    .leftSide label{
        white-space: nowrap;
    }
}

@media (max-width:439px) {
    .leftSide{
        padding: 2rem 0;
    }
}