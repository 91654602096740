.App {
  width:100%;
  height:auto;
  display:flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

body{
  padding: 0;
  margin: 0;
}

*::selection{
  background-color: #fcf157b4;
  color: #000;
}