@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Inconsolata:wght@200..900&family=Indie+Flower&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Cabin:ital,wght@0,400..700;1,400..700&family=Inconsolata:wght@200..900&family=Indie+Flower&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Questrial&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Maven+Pro:wght@400..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Questrial&family=Righteous&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
:root{
    --ig:linear-gradient(90deg, #e03894, #f39c12);
    --yt:linear-gradient(90deg, #e21c1c, #f0eeec);
    --x:linear-gradient(90deg, #231d47, #222120);
    --fb: linear-gradient(90deg,#4a90d1, #e2e0e0);
}
.footer-content{
    display: flex;
    flex-direction: column;
    background-color: #121619;
}
.mainname{
    justify-content: center;
    align-content: center;
    margin: 3rem 0rem;
}
.mainname h1{
    text-align: center;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #e2e0e0;
}
.content-f{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3, 1fr));
    grid-template-rows: minmax(250px, auto);
    justify-content: space-between;
    margin: 0rem 8rem;
}
.social-m{
    grid-column: 1;
    grid-row: 1;
    padding: 2rem;
}

.contactos{
    grid-column: 2;
    grid-row: 1;
    padding: 2rem;
}
.policies{
    grid-column: 3;
    grid-row: 1;
    padding: 2rem;
}


li.nodec{
    list-style: none;
}
li.nodec a{
    font-size: 0.9rem;
    text-align:left;
}

.phone{
    display: flex;
    flex-direction: row;
    padding: 1rem 0.8rem;
    color:#000;
}
.phone-i{
    align-self: center;
    font-size: 1rem;
    color:#e2e0e0;
}

.phone h4{
    padding: 0 1rem;
    font-size: 1rem;
    color: #e2e0e0;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.email{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    color:#000;
}

.email-i{
    align-self: center;
    font-size: 1rem;
    color: #e2e0e0;
}

.email h4{
    padding: 0 1rem; 
    font-size: 1rem;
    color: #e2e0e0;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.social-m h2{
    color: #e2e0e0;
    text-align: center;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
}

ul.social-i{
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.contactos h2{
    color: #e2e0e0;
    text-align: center;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.policies h2{
    color: #e2e0e0;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-justify:newspaper;
    text-align: left;
}

.policies ul{
    padding: 1rem 0;
}

.pandp{
    color: #e2e0e0;
    text-align: center;
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
    white-space: nowrap;
}
.se{
    padding: 0 0.2rem;
}

.so{
  padding: 1rem;
  background-color: rgb(75, 88, 88);
  border-radius: 3rem;
  color:#000000;
}

.so1{
    padding: 1rem;
    background-color: rgb(75, 88, 88);
    border-radius: 3rem;
    color:#000000;
}

.so2{
    padding: 1rem;
    background-color: rgb(75, 88, 88);
    border-radius: 3rem;
    color:#000000;
}
.so3{
    padding: 1rem;
    background-color: rgb(75, 88, 88);
    border-radius: 3rem;
    color:#000000;
}

.so:hover{
    background: var(--yt);
}

.so1:hover{
    background: var(--x);
}

.so2:hover{
    background: var(--ig);
}

.so3:hover{
    background: var(--fb);
}

@media (max-width:600px) {
    .content-f{
        /* width: 100%;
        height: 100%; */
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(3, 1fr));
        margin: 0;
    justify-content: center;
    align-items: center;
        width: 100%;
    }
    .social-m{
        grid-row: 1;
        grid-column: 1;
        padding: 0;
    }
    .social-m h2{
        text-align: center;
    }
    .contactos{
        grid-row: 2;
        grid-column: 1;
        padding: 0;
    }
    .policies{
        grid-row: 3;
        grid-column: 1;
        padding: 0;
        margin-bottom: 1rem;
    }
    .policies h2{
        text-align: center;
    }
    .policies ul{
        /* align-content: center;
        align-items: center; */
        /* text-align: center; */
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        width: 100%;
    }
}