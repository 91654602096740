*{
    margin:0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    outline:none;
}

:root{
    --fullbackground: linear-gradient(90deg, #000, #121619);
}

.bcgdsm{
    background: var(--fullbackground);
    padding: 2rem 25%;
}

.titami{
    padding: 2rem;
}
.titami h1{
    font-size: 2.5rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 0.8rem;
}
.titami h4{
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    margin-bottom: 0.4rem;
}
.titami h5{
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
}
.igsm img{
    width: 100%;
}
.dessm{
    margin-top: 1rem;
    padding: 0.8rem;
}
.dessm h1{
    font-size: 1.6rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;;

}
.dessm h2{
    font-size: 1.2rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
}
.dessm h3{
    font-size: 1rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
    
}
ul li{
    font-size: 1.2rem;
    color:#e4e2e2;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
    margin-left: 5%;
    list-style:circle;
}
